import React from "react"
import { PageLayout } from "../components/Layout"
import TextSectionBackground from "../components/TextSectionBackground"
import { SectionHeader, SubSectionHeader } from "../components/Typography"
import { useStaticQuery, graphql } from "gatsby"
import ImageRow from "../components/ImageRow"
import SEO from "../components/SEO"

const Lodge = () => {
  const {
    contentfulAboutPage: { line },
  } = useStaticQuery(graphql`
    {
      contentfulAboutPage {
        line {
          header
          description {
            json
          }
          imageDesction {
            fluid(maxWidth: 610, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  `)

  return (
    <PageLayout>
      <SEO 
      title="About Us" 
      description="Mavela Game Lodge is nestled in the heart of the magnificent Big 5 Manyoni Private Game Reserve. We pride ourselves on giving our guests an authentic African bush experience." 
      />
      <TextSectionBackground pa="4% 10%">
        <SectionHeader>Who We Are</SectionHeader>
        <SubSectionHeader>
          We pride ourselves on giving our guests an authentic African bush
          experience
        </SubSectionHeader>
        {line.map((about: any, i: number) => (
          <ImageRow
            id={about.header.split(" ").slice(-1)[0]}
            key={about.header}
            reverse={!!(i % 2)}
            pic={about.imageDesction.fluid}
            header={about.header}
            text={about.description.json}
          ></ImageRow>
        ))}
      </TextSectionBackground>
    </PageLayout>
  )
}

export default Lodge

import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => (
  <p className="align-center blog-paragraph">{children}</p>
)

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  flex-wrap: ${(props) => (props.reverse ? "wrap" : "wrap-reverse")};
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  margin-top: 3rem;
  margin-bottom: 5rem;
  @media only screen and (max-width: 980px) {
    flex-wrap: wrap-reverse;
    flex-direction: row;
    margin-bottom: 3rem;
  }
  .section {
    @media only screen and (max-width: 980px) {
      width: 100%;
    }
    width: 47.25%;
    &.img {
      max-width: 610px;
    }
  }
  .center {
    text-align: center;
    margin: auto;
  }
  h3 {
    color: #444444;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    font-weight: 700;
    font-size: 28px;
    margin-top: 0;
    @media all and (max-width: 680px) {
      font-size: 22px;
    }
  }
  p {
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    color: #666;
    font-weight: 500;
    line-height: 1.7em;
    font-size: 18px;
  }
`

const ImageRow = ({
  pic = null,
  header,
  text,
  reverse = false,
  id = null,
  richText = true,
}) => {
  return (
    <Wrapper id={id} reverse={reverse}>
      {!!pic && (
        <div className="section img">
          <Img fluid={pic} />
        </div>
      )}
      <div className={!!pic ? "section" : "center"}>
        <h3>{header}</h3>
        {richText ? documentToReactComponents(text, options) : text}
      </div>
    </Wrapper>
  )
}

export default ImageRow
